import { Component, OnInit } from '@angular/core';
import { SCopyCaseEntryService } from '../pages/copy-case/copy-case-entry/s-copy-case-entry.service';

@Component({
  selector: 'app-print-list-of-copy-cases',
  templateUrl: './print-list-of-copy-cases.component.html',
  styleUrls: ['./print-list-of-copy-cases.component.scss']
})
export class PrintListOfCopyCasesComponent implements OnInit {

  todays_date= new Date()
  
  constructor(public objSCopyCaseEntryService: SCopyCaseEntryService) { }

  ngOnInit(): void {
    this.objSCopyCaseEntryService.getCopyCasesCenterList()
    this.objSCopyCaseEntryService.getCopyCases()
  }
}
