import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.MENU.TEXT',
        isTitle: true
    },
    {
        id: 2,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'bx-home-circle',
        link: '/dashboard',
        parentId: 2
    },
    {
        id: 7,
        isLayout: true
    },
    {
        id: 2,
        label: 'ADMIN ',
        isTitle: true
    },
    {
        id: 3,
        label: 'Copy Case Entry',
        icon: 'bx-list-ul',
        link: '/copy-case/copy-case-entry',
    },
    {
        id: 3,
        label: 'Copy Case Entry List',
        icon: 'bx-list-ul',
        link: '/copy-case/copy-case-entry-list',
    },
    {
        id: 3,
        label: 'ANNEXURE - II',
        icon: 'bx-list-ul',
        link: '/copy-case/copy-case-annexure-ii',
    },
    {
        id: 3,
        label: 'ANNEXURE - III',
        icon: 'bx-list-ul',
        link: '/copy-case/copy-case-annexure-iii',
    },
    {
        id: 3,
        label: 'ANNEXURE - IV',
        icon: 'bx-list-ul',
        link: '/copy-case/copy-case-annexure-iv',
    },
    {
        id: 4,
        label: 'ANNEXURE - V',
        icon: 'bx-list-ul',
        link: '/copy-case/copy-case-annexure-v',
    },
    {
        id: 4,
        label: 'ANNEXURE - V (A) to V (D)',
        icon: 'bx-list-ul',
        link: '/print-annexure-v-a-to-v-d',
    },    
    {
        id: 5,
        label: 'PRINT LETTERS',
        icon: 'bx-list-ul',
        link: '/copy-case/print-letter-by-college',
    }
];


export const MENU_CENTER: MenuItem[] = [
    {
        id: 2,
        label: 'CENTER USER ',
        isTitle: true
    },
    {
        id: 3,
        label: 'Copy Case Entry',
        icon: 'bx-list-ul',
        link: '/center-copy-case-entry',
    },
    // {
    //     id: 3,
    //     label: 'Copy Case Entry List',
    //     icon: 'bx-list-ul',
    //     link: '/copy-case/copy-case-entry-list',
    // }
];

