<div class="book">
    <div class="page">
        <div class="subpage">
            <div class="row" style="display: grid;grid-template-columns: 75% 25%;">
                <div style="font-size: 22px;font-weight: 500;">
                    SANT GADGE BABA AMRAVATI UNIVERSITY
                    <div style="font-size: 12px;font-weight: 500;text-align: justify;text-transform: uppercase;">
                        Consoliated statement showing the Category of the Case & the Punishment recommended if any, by
                        the foil
                        Categorizer to the examinees for use of Malpractices in the University Examinations.
                    </div>
                </div>
                <div style="font-size: 12px;font-weight: 500;">
                    <span style="font-size: 18px;">ANNEXURE - V</span> <br>
                    Page No - ..................... <br>
                    Date - {{todays_date | date: 'dd-MM-yyyy'}} <br>
                    <span style="font-weight: 600;">EXAM - WINTER-2023</span>
                </div>
            </div>
            <br>
            <div class="row">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th style="text-align: center;vertical-align: middle; width: 5%;text-align: center;">
                                Case No</th>
                            <th style="text-align: center;vertical-align: middle; width: 13%;">
                                Name of Examinee</th>
                            <th style="text-align: center;vertical-align: middle; width: 25%;">
                                Name of Exam</th>
                            <th style="text-align: center;vertical-align: middle; width: 10%;">
                                Roll No</th>
                            <th style="text-align: center;vertical-align: middle; width: 29%;">
                                Category of the cases & the punishment
                                Recommended by
                                <div style="display: grid;grid-template-columns: 33.33% 33.33% 33.33%;padding-top: 5px;
                                border-top: 1px solid rgba(128, 128, 128, 0.185);">
                                    <div style="text-align: center;">FCAT</div>
                                    <div style="text-align: center;">CFCAT</div>
                                    <div style="text-align: center;">TFCAT</div>
                                </div>
                            </th>
                            <th style="text-align: center;vertical-align: middle; width: 5%;">
                                Final Category</th>
                            <th style="text-align: center;vertical-align: middle; width: 8%;">
                                Punishment</th>
                            <th style="text-align: center;vertical-align: middle; width: 7%;">
                                Remark</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let obj of objSCopyCaseEntryService.arrCopyCaseEntry;let i = index"
                            style="page-break-inside:avoid; page-break-after:auto">
                            <td style="text-align: center;font-weight: 600;">
                                {{obj.case_no}} <br>
                            </td>
                            <td style="font-weight: 500;">
                                {{obj.student_first_name}} {{obj.student_middle_name}}
                                {{obj.student_last_name}}
                            </td>
                            <td>
                                {{obj.exam_name}}
                            </td>
                            <td>
                                {{obj.student_seat_no}} <br>
                                ({{obj.center_code}})
                            </td>
                            <td>
                                <div style="display: grid;grid-template-columns: 33.33% 33.33% 33.33%;">
                                    <div style="text-align: center;">
                                        <label *ngIf="obj.annex_II_cat != 0" style="text-align: center;">
                                            {{obj.annex_II_cat | number:'2.0'}}
                                        </label>
                                    </div>
                                    <div style="text-align: center;">
                                        <label *ngIf="obj.annex_III_cat != 0" style="text-align: center;">
                                            {{obj.annex_III_cat | number:'2.0'}}
                                        </label>
                                    </div>
                                    <div style="text-align: center;">
                                        <label *ngIf="obj.annex_IV_cat != 0" style="text-align: center;">
                                            {{obj.annex_IV_cat | number:'2.0'}}
                                        </label>
                                    </div>
                                </div>
                            </td>
                            <td style="text-align: center;">
                                <!-- <div style="text-align: center;"
                                    *ngIf="obj.annex_II_cat != null && obj.annex_II_cat != 0 && 
                                        obj.annex_III_cat == '' && obj.annex_III_cat == 0 && obj.annex_IV_cat == '' && obj.annex_IV_cat == 0">
                                    {{obj.annex_II_cat | number:'2.0'}}</div>
                                <div style="text-align: center;"
                                    *ngIf="obj.annex_III_cat != '' && obj.annex_III_cat != 0 && obj.annex_IV_cat == '' && obj.annex_IV_cat == 0">
                                    {{obj.annex_III_cat | number:'2.0'}}
                                </div>
                                <div style="text-align: center;"
                                    *ngIf="obj.annex_II_cat != '' && obj.annex_II_cat != 0 && 
                                        obj.annex_III_cat != '' && obj.annex_III_cat != 0 && obj.annex_IV_cat != '' && obj.annex_IV_cat != 0">
                                    {{obj.annex_IV_cat | number:'2.0'}}</div> -->
                                <div *ngIf="obj.annex_II_cat === obj.annex_III_cat && obj.annex_III_cat != 40">
                                    {{obj.annex_III_cat}}</div>
                                <div style="font-size: 11px;"
                                    *ngIf="(obj.annex_II_cat != obj.annex_III_cat || obj.annex_II_cat == 40)  && obj.annex_IV_cat ==0">
                                    -</div>
                                <div style="font-size: 11px;"
                                *ngIf="(obj.annex_II_cat != obj.annex_III_cat && obj.annex_II_cat != 40 && obj.annex_IV_cat !=0) || obj.annex_II_cat == 40 ">
                                    {{obj.annex_IV_cat}}</div>
                            </td>
                            <td style="font-size: 12px;text-align: center;">
                                <!-- <div style="font-size: 11px;"
                                    *ngIf="obj.annex_II_punishment != '' && obj.annex_II_punishment != 0 && 
                                        obj.annex_III_punishment == '' && obj.annex_III_punishment == 0 && obj.annex_IV_punishment == '' && obj.annex_IV_punishment == 0
                                        && obj.annex_II_cat == obj.annex_III_cat">
                                    {{obj.annex_II_punishment}}</div>
                                <div style="font-size: 11px;"
                                    *ngIf="obj.annex_III_punishment != '' && obj.annex_III_punishment != 0 && obj.annex_IV_punishment == '' && obj.annex_IV_punishment == 0
                                    && obj.annex_II_cat == obj.annex_III_cat">
                                    {{obj.annex_III_punishment}}
                                </div>
                                <div style="font-size: 11px;"
                                    *ngIf="obj.annex_II_punishment != '' && obj.annex_II_punishment != 0 && 
                                        obj.annex_III_punishment != '' && obj.annex_III_punishment != 0 && obj.annex_IV_punishment != '' && obj.annex_IV_punishment != 0
                                        && obj.annex_II_cat == obj.annex_III_cat">
                                        {{obj.annex_IV_punishment}}</div> -->
                                <div style="font-size: 11px;"
                                    *ngIf="obj.annex_II_cat === obj.annex_III_cat  && obj.annex_III_cat  != 40">
                                    {{obj.annex_III_punishment}}</div>
                                <!-- <div style="font-size: 11px;" *ngIf="obj.annex_II_cat != obj.annex_III_cat || obj.annex_II_cat == 40">
                                        -</div> -->
                                <div style="font-size: 11px;"
                                    *ngIf="(obj.annex_II_cat != obj.annex_III_cat || obj.annex_II_cat == 40)  && obj.annex_IV_cat ==0">
                                    -</div>
                                <div style="font-size: 11px;"
                                    *ngIf="(obj.annex_II_cat != obj.annex_III_cat && obj.annex_II_cat != 40 && obj.annex_IV_cat !=0) || obj.annex_II_cat == 40 ">
                                    {{obj.annex_IV_punishment}}</div>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row" style="margin-top: 3rem;display: grid;grid-template-columns: 50% 50%;">
                <div  style="text-align: center;font-weight: 600;">
                    SUBMITTED FOR CONSIDERATION
                </div>
                <div  style="text-align: center;font-weight: 600;">
                    CHAIRMAN ...............................
                </div>
            </div>
            <div style="display: grid;grid-template-columns: 20% 30% 50%;">
                <div  style="text-align: center;font-weight: 600;padding-top: 3rem;">
                    ASSTT.REGISTRAR <br>
                    ÇONFIDENTIAL
                </div>
                <div  style="text-align: center;font-weight: 600;padding-top: 3rem;">
                    DIRECTOR<br>
                    BORAD OF EXAM & EVALUATION
                </div>
                <div 
                    style="display: grid;grid-template-columns: 50% 50%;line-height: 2rem;font-weight: 600;margin-top: 1rem;">
                    <div>
                        1. MEMBER ...............................
                    </div>
                    <div>
                        2. MEMBER ...............................
                    </div>
                    <div>
                        3. MEMBER ...............................
                    </div>
                    <div>
                        4. MEMBER ...............................
                    </div>
                    <div>
                        5. MEMBER ...............................
                    </div>
                    <div>
                        6. MEMBER ...............................
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>