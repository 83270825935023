import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// import { AuthGuard } from './core/guards/auth.guard';
import { LayoutComponent } from './layouts/layout.component';
import { CyptolandingComponent } from './cyptolanding/cyptolanding.component';
import { Page404Component } from './extrapages/page404/page404.component';
import { AuthGuard } from './services/auth.guard';
import { PrintCaseReportComponent } from './print-case-report/print-case-report.component';
import { PrintCaseAnnexIIReportComponent } from './print-case-annex-ii-report/print-case-annex-ii-report.component';
import { PrintCaseAnnexIIIReportComponent } from './print-case-annex-iii-report/print-case-annex-iii-report.component';
import { PrintCaseAnnexIVReportComponent } from './print-case-annex-iv-report/print-case-annex-iv-report.component';
import { PrintCaseAnnexVReportComponent } from './print-case-annex-v-report/print-case-annex-v-report.component';
import { PrintListOfCopyCasesComponent } from './print-list-of-copy-cases/print-list-of-copy-cases.component';
import { PrintCaseAnnexVIReportComponent } from './print-case-annex-v-i-report/print-case-annex-v-i-report.component';
import { PrintCaseAnnexIVReportTwoComponent } from './print-case-annex-iv-report-two/print-case-annex-iv-report-two.component';
import { PrintAnnexureVAToVDComponent } from './print-annexure-v-a-to-v-d/print-annexure-v-a-to-v-d.component';
import { PrintLetterComponent } from './print-letter/print-letter.component';

const routes: Routes = [
  { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
  // tslint:disable-next-line: max-line-length
  { path: '', component: LayoutComponent, loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) },
  // , canActivate: [AuthGuard] 
  { path: 'pages', loadChildren: () => import('./extrapages/extrapages.module').then(m => m.ExtrapagesModule) },

  // , canActivate: [AuthGuard] 
  // { path: 'crypto-ico-landing', component: CyptolandingComponent },
  // { path: '**', component: Page404Component },
  { path: 'print-case-report', component: PrintCaseReportComponent },
  { path: 'print-case-annex-II-report', component: PrintCaseAnnexIIReportComponent},
  { path: 'print-case-annex-III-report', component: PrintCaseAnnexIIIReportComponent},
  { path: 'print-case-annex-IV-report', component: PrintCaseAnnexIVReportComponent},
  { path: 'print-case-annex-V-report', component: PrintCaseAnnexVReportComponent},
  { path: 'print-case-list', component: PrintListOfCopyCasesComponent},
  { path: 'print-case-annex-V-i-report', component: PrintCaseAnnexVIReportComponent},
  {path:'print-case-annex-IV-report-2', component: PrintCaseAnnexIVReportTwoComponent},
  {path: 'print-annexure-v-a-to-v-d', component: PrintAnnexureVAToVDComponent},
  {path :'print-letter', component: PrintLetterComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
