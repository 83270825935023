<div class="book">
    <div class="page" *ngFor="let obj of objSLetterService.arrmLetterData">
        <div class="subpage">
            <div class="row">
                <div style="display: flex;justify-content: center;">
                    <img style="width: 70px;"
                        src="https://upload.wikimedia.org/wikipedia/en/thumb/4/42/Sant_Gadge_Baba_Amravati_University_logo.png/220px-Sant_Gadge_Baba_Amravati_University_logo.png">
                </div>
                <div style="font-size: 22px;font-weight: 600;text-align: center;border-bottom: 1px solid;">
                    SANT GADGE BABA AMRAVATI UNIVERSITY, AMRAVATI
                </div>
            </div>
            <div class="row" style="margin-top: 10px;">
                <div class="col-md-12">
                    <div>
                        To,
                    </div>
                    <div style="margin-left: 2rem;margin-right: 25%;line-height: 1.2rem;font-weight: 600;">
                        {{obj.student_first_name}} <br>
                        {{obj.student_address}}
                    </div>
                </div>
                <div class="col-md-12" style="font-weight: 600;margin-top: 10px;">
                    Sub.: Use of malpractices in the examination/s.
                </div>
                <div class="col-md-12" style="text-align: justify;margin-top: 10px;">
                    <span style="margin-left: 3rem;">I</span> am directed to inform you that the Hon'ble Vice Chancellor
                    in exercise of powers
                    vested in him under clause(b) of Sub section (5) of section 48 of Maharashtra Public
                    Universities Act-2016, found you guilty of using malpractice/s in the examination.
                    I am further to inform you that for using such malpractice/s in the examination you
                    have been debarred from appearing for any University examination up to & inclusive
                    of the examination shown in column No.4 of the table. Your Case No. is mentioned in
                    column No.1 of the table. Examination, year and the category relating to your case is
                    shown in column No.2,3 & 5 of the Table respectively.
                </div>
                <div class="col-md-12" style="margin-top: 10px;">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th rowspan="2">Case No.</th>
                                <th rowspan="2">Examination and Roll No.</th>
                                <th rowspan="2">Year</th>
                                <th>Punishment </th>
                                <th rowspan="2">Category</th>
                                <th colspan="2">This letter is</th>
                            </tr>
                            <tr>
                                <th>Debarred up-to and inclusive of exam.</th>
                                <th>Dated</th>
                                <th>Numbered</th>
                            </tr>
                            <tr>
                                <th style="width: 5%;">1</th>
                                <th style="width: 25%;">2</th>
                                <th style="width: 12%;">3</th>
                                <th style="width: 20%;">4</th>
                                <th  style="width: 8%;">5</th>
                                <th  style="width: 15%;">6</th>
                                <th  style="width: 15%;">7</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr >
                                <td>{{obj.case_no}}</td>
                                <td style="text-align: left;">{{obj.exam_name}} <br>
                                    ( {{obj.student_seat_no}} )</td>
                                <td>WINTER-2023</td>
                                <td>{{obj.punishment_year}}</td>
                                <td>{{obj.punishment_year_cat}}</td>
                                <td>02-04-2024</td>
                                <td>SGBAU/5/515/20/2024</td>
                            </tr>
                            <tr>
                                <td colspan="7" style="font-size: 14px;">
                                    <ul>
                                        <li style="text-align: justify;">
                                            {{obj.punishment}}
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-md-12" style="line-height: 1.2rem;text-align: justify;">
                    If, after the said punishment, you are eligible for appearing in the ensuing University
                    examination, and the date prescribed for submitting the examination form of the said
                    examination is expired, then you should send your examination form to the University
                    within ten days from the date of this letter. If, such is the case,
                    you are further instructed to enclose the attested copy of this letter with your examination form.
                </div>
                <div style="display: grid;grid-template-columns: 48% 62%;margin-top: 2rem;">
                    <div></div>
                    <div style="text-align: center;line-height: 1.2rem;font-weight: 600;">
                        for Director <br>
                        Board of Examination and Evaluation <br>
                        Sant Gadge Baba Amravati University, Amravati
                    </div>
                </div>
                <div class="col-md-12"
                    style="text-align: justify;line-height: 1.2rem;font-weight: 600;border-top: 1px solid;font-size: 11px;">
                    Important Instruction :- If any Examinee feels aggrieved by the category of use of
                    malpractice/s, (use of unfairmeans) alloted to him it shall be open for him to make a
                    "review application" to the Director, Board of Examination & Evaluation within 15
                    Days from the date of this Letter in a prescribed form on payment of Rs.5/- If such
                    review application is found to be without any basis, the Hon'ble Vice-Chancellor may
                    increase the punishment. Prescribed review application form will be available in the Confidential
                    Section.
                </div>
                <div class="col-md-12"
                    style="text-align: center;line-height: 1.2rem;font-weight: 600;border-top: 1px solid;font-size: 12px;text-transform: uppercase;">
                    This is a computer-generated document. No signature is required.
                    </div>
            </div>
        </div>
    </div>
</div>