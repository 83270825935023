<div class="book">
    <div class="page">
        <div class="subpage">
            <div class="row" style="display: flex; justify-content: right; font-size: 22px;font-weight: 600;">
                CHECK LIST 
                <hr>
            </div>
            <div class="row" style="display: grid;grid-template-columns: 75% 25%;">
                <div style="font-size: 22px;font-weight: 500;text-align: center;">
                    SANT GADGE BABA AMRAVATI UNIVERSITY
                    <div style="display: grid;grid-template-columns: 40% 60%;margin-top: .5rem;">
                        <div style="font-size: 16px;font-weight: 500;text-align: left;">
                            U/F
                        </div>
                        <div style="font-size: 16px;font-weight: 500;text-align: left;">
                            EXAM:
                        </div>
                    </div>
                </div>
                <div style="font-size: 12px;font-weight: 500;">
                    Page No - ..................... <br><br>
                    Date - {{todays_date | date: 'dd-MM-yyyy'}}
                </div>
            </div>
            <hr>

            <div class="row">
                <div *ngFor="let obj1 of objSCopyCaseEntryService.arrmCenterList">
                    <div
                        style="box-shadow: 0 0 1px grey;padding: 10px;font-weight: 600;font-size: 12px;margin-bottom: 6px;text-transform: uppercase;">
                        CENTRE - &nbsp;&nbsp; {{obj1.center_code}} - {{obj1.college_name}}
                    </div>
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th style="vertical-align: middle; text-align: center; width: 5%;">Sr No</th>
                                <th style="vertical-align: middle; text-align: center; width: 8%;">Case No</th>
                                <th style="vertical-align: middle; text-align: center; width: 32%;">Name of Examinee</th>
                                <th style="vertical-align: middle; text-align: center; width: 20%;">Name of Exam</th>
                                <th style="vertical-align: middle; text-align: center; width: 10%;">Roll No</th>
                                <th style="vertical-align: middle; text-align: center; width: 20%;">Subject</th>
                            </tr>
                        </thead>
                        <tbody style="page-break-inside:avoid; page-break-after:auto"
                            *ngFor="let obj of objSCopyCaseEntryService.arrCopyCaseEntry; let i = index">
                            <tr *ngIf="obj.center_code == obj1.center_code">
                                <td style="text-align: center;">
                                    <!-- {{i+1}} -->
                                    {{obj.sr_no_new}}
                                </td>
                                <td style="text-align: center;font-weight: 600;">
                                    {{obj.case_no}} 
                                </td>
                                <td style="font-weight: 500;">
                                    {{obj.student_first_name}} {{obj.student_middle_name}}
                                    {{obj.student_last_name}} <br>
                                    {{obj.student_address}}
                                </td>
                                <td>
                                    {{obj.exam_name}} 
                                    {{obj.paper}}
                                </td>
                                <td>
                                    {{obj.student_seat_no}}
                                </td>
                                <td>
                                    {{obj.subject}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                </div>
        </div>
    </div>
</div>