import { Component, OnInit } from '@angular/core';
import { SLetterService } from './s-letter.service';

@Component({
  selector: 'app-print-letter',
  templateUrl: './print-letter.component.html',
  styleUrls: ['./print-letter.component.scss']
})
export class PrintLetterComponent implements OnInit {

  constructor(public objSLetterService: SLetterService) { }

  ngOnInit(): void {
    this.objSLetterService.getLetterData()
  }

}
