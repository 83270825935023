import { Component, OnInit } from '@angular/core';
import { SCopyCaseEntryService } from '../pages/copy-case/copy-case-entry/s-copy-case-entry.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-print-case-report',
  templateUrl: './print-case-report.component.html',
  styleUrls: ['./print-case-report.component.scss']
})
export class PrintCaseReportComponent implements OnInit {

  constructor(public objSCopyCaseEntryService: SCopyCaseEntryService, private route: ActivatedRoute) { 
    this.route.queryParams.subscribe((params: any) => {
      if (params.app_id != undefined) {
        this.objSCopyCaseEntryService.getCopyCaseBYAppId(params.app_id)
      }
    })
  }

  ngOnInit(): void {
  }

}
