<div class="book">
    <div class="page">
        <div class="subpage">
            <div class="row" style="display: grid;grid-template-columns: 70% 30%;">
                <div style="font-size: 22px;font-weight: 500;">
                    SANT GADGE BABA AMRAVATI UNIVERSITY
                </div>
                <div style="font-size: 16px;font-weight: 500;text-align: right;">
                    ANNEXURE - IV <br>
                    THIRD CATEGORIZER
                </div>
            </div>
            <div class="row">
                <div style="font-size: 13px;font-weight: 500;text-align: justify;">
                    Consoliated statement showing the Category of the Case & the Punishment recommended if any, by the
                    foil Categorizer to the examinees for use of Malpractices in the University Examinations.
                    <br>
                    <span style="font-weight: 600;">EXAM - WINTER 2023</span>
                </div>
            </div>
            <br>
            <div class="row">
                <table class="table">
                    <thead>
                        <tr>
                            <th style="vertical-align: middle; width: 5%;text-align: center;">Case No</th>
                            <th style="vertical-align: middle; width: 25%;">Name of Examinee</th>
                            <th style="vertical-align: middle; width: 40%;">Name of Exam</th>
                            <th style="vertical-align: middle; width: 10%;">Roll No</th>
                            <th style="vertical-align: middle; width: 5%;">Category</th>
                            <th style="vertical-align: middle; width: 15%;">Punishment</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let obj1 of objSCopyCaseEntryService.arrCopyCaseEntry2;let i = index">
                        <tr *ngIf="obj1.sr_id_annex_4 == 1">
                            <td style="text-align: center;font-weight: 600;">
                                {{obj1.case_no}} <br>
                            </td>
                            <td style="font-weight: 500;">
                                {{obj1.student_first_name}} {{obj1.student_middle_name}}
                                {{obj1.student_last_name}}
                            </td>
                            <td>
                                {{obj1.exam_name}} <br>
                                {{obj1.subject}}
                            </td>
                            <td style="text-align: center;">
                                {{obj1.student_seat_no}} <br>
                                ({{obj1.center_code}})
                            </td>
                            <td style="text-align: center;">
                                <label *ngIf="obj1.annex_IV_cat != 0">
                                    {{obj1.annex_IV_cat}}
                                </label>
                            </td>
                            <td style="font-size: 12px;">
                                {{obj1.annex_IV_punishment}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- <div style="font-size: 15px;text-align: center;font-weight: 600;">
                {{obj.annex_IV_assign}} 
            </div> -->
            <div style="font-size: 13px;text-align: right;font-weight: 500;margin-top: 3rem;">
                Name & Signature of the Third Categorizer
            </div>
        </div>
    </div>
    <div class="page">
        <div class="subpage">
            <div class="row" style="display: grid;grid-template-columns: 70% 30%;">
                <div style="font-size: 22px;font-weight: 500;">
                    SANT GADGE BABA AMRAVATI UNIVERSITY
                </div>
                <div style="font-size: 16px;font-weight: 500;text-align: right;">
                    ANNEXURE - IV <br>
                    THIRD CATEGORIZER
                </div>
            </div>
            <div class="row">
                <div style="font-size: 13px;font-weight: 500;text-align: justify;">
                    Consoliated statement showing the Category of the Case & the Punishment recommended if any, by the
                    foil Categorizer to the examinees for use of Malpractices in the University Examinations.
                    <br>
                    <span style="font-weight: 600;">EXAM - WINTER 2023</span>
                </div>
            </div>
            <br>
            <div class="row">
                <table class="table">
                    <thead>
                        <tr>
                            <th style="vertical-align: middle; width: 5%;text-align: center;">Case No</th>
                            <th style="vertical-align: middle; width: 25%;">Name of Examinee</th>
                            <th style="vertical-align: middle; width: 40%;">Name of Exam</th>
                            <th style="vertical-align: middle; width: 10%;">Roll No</th>
                            <th style="vertical-align: middle; width: 5%;">Category</th>
                            <th style="vertical-align: middle; width: 15%;">Punishment</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let obj1 of objSCopyCaseEntryService.arrCopyCaseEntry2;let i = index">
                        <tr *ngIf="obj1.sr_id_annex_4 == 2">
                            <td style="text-align: center;font-weight: 600;">
                                {{obj1.case_no}} <br>
                            </td>
                            <td style="font-weight: 500;">
                                {{obj1.student_first_name}} {{obj1.student_middle_name}}
                                {{obj1.student_last_name}}
                            </td>
                            <td>
                                {{obj1.exam_name}} <br>
                                {{obj1.subject}}
                            </td>
                            <td style="text-align: center;">
                                {{obj1.student_seat_no}} <br>
                                ({{obj1.center_code}})
                            </td>
                            <td style="text-align: center;">
                                <label *ngIf="obj1.annex_IV_cat != 0">
                                    {{obj1.annex_IV_cat}}
                                </label>
                            </td>
                            <td style="font-size: 12px;">
                                {{obj1.annex_IV_punishment}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- <div style="font-size: 15px;text-align: center;font-weight: 600;">
                {{obj.annex_IV_assign}} 
            </div> -->
            <div style="font-size: 13px;text-align: right;font-weight: 500;margin-top: 3rem;">
                Name & Signature of the Third Categorizer
            </div>
        </div>
    </div>
</div>