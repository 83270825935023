import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { mCenterList } from '../pages/copy-case/copy-case-entry/m-copy-case-entry';

@Injectable({
  providedIn: 'root'
})
export class SLetterService {

  arrmLetterData: mLetterData[] = []
  arrmPunishment: mPunishment[] = []

  arrCollege: mCenterList[] = []
  college_code: number = 0

  constructor(public http: HttpClient) { }


  getCollegeByCases() {
    this.http.get(environment.apiKey + 'college_master/Getcollege_by_copy_cases').subscribe(
      (res: any) => {
        this.arrCollege = res
      }
    )
  }

  getLetterData() {
    this.http.get(environment.apiKey + 'copy_case_punishment').subscribe(
      (res: any) => {
        this.arrmPunishment = res

        if (this.college_code == 0) {
          this.http.get(environment.apiKey + 'copy_case_entry').subscribe(
            (res: any) => {
              this.arrmLetterData = res
              
              this.arrmLetterData.forEach(element => {
                if (element.annex_III_cat == element.annex_II_cat) {
                  element.punishment = this.arrmPunishment.filter(s => s.punishment_id == element.annex_III_cat)[0].nature
                  element.punishment_year = element.annex_III_punishment
                  element.punishment_year_cat = element.annex_III_cat
                }
                else {
                  element.punishment = this.arrmPunishment.filter(s => s.punishment_id == element.annex_IV_cat)[0].nature
                  element.punishment_year = element.annex_IV_punishment
                  element.punishment_year_cat = element.annex_IV_cat
                }
              });
            }
          )
        }
        else {
          this.http.get(environment.apiKey + 'copy_case_entry/Getcopy_case_entry_by_College?college_code='+this.college_code).subscribe(
            (res: any) => {
              this.arrmLetterData = res

              this.arrmLetterData.forEach(element => {
                if (element.annex_III_cat == element.annex_II_cat) {
                  element.punishment = this.arrmPunishment.filter(s => s.punishment_id == element.annex_III_cat)[0].nature
                  element.punishment_year = element.annex_III_punishment
                  element.punishment_year_cat = element.annex_III_cat
                }
                else {
                  element.punishment = this.arrmPunishment.filter(s => s.punishment_id == element.annex_IV_cat)[0].nature
                  element.punishment_year = element.annex_IV_punishment
                  element.punishment_year_cat = element.annex_IV_cat
                }
              });
            }
          )
        }
      }
    )
  }
}

export class mPunishment {
  id: number
  punishment_id: number
  nature: string
  quantum: string
  punishment_one: number
  punishment_two: number
}

export class mLetterData {
  app_id: number = 0
  student_first_name: string = ''
  student_address: string = ''
  student_mobile: string = ''
  student_seat_no: string = ''
  center_code: number = 0
  exam_name: string = ''
  subject: string = ''
  college_code: number = 0
  session: number = 0
  annex_III_cat: number = 0
  annex_II_cat: number = 0
  annex_IV_cat: any
  annex_III_punishment: string = ''
  annex_II_punishment: string = ''
  annex_IV_punishment: string = ''
  case_no: number = 0
  punishment = ''
  punishment_year = ''
  punishment_year_cat = 0
}