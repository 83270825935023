<div class="book">
    <div class="page" *ngFor="let objPage of objSCopyCaseEntryService.arrmAnnexure_pages">
        <div class="subpage">
            <div class="row" style="display: grid;grid-template-columns: 70% 30%;">
                <div style="font-size: 20px;font-weight: 500;">
                    SANT GADGE BABA AMRAVATI UNIVERSITY
                </div>
                <div style="font-size: 14px;font-weight: 500;text-align: right;">
                    ANNEXURE - II <br>
                    FOIL CATEGORIZER
                </div>
            </div>
            <div class="row">                
                <div style="font-size: 12px;font-weight: 500;">
                    Consoliated statement showing the Category of the Case & the Punishment recommended if any, by the foil 
                    Categorizer to the examinees for use of Malpractices in the University Examinations.
                </div>
            </div>  
            <div class="row">
                <table class="table">
                    <thead>
                        <tr>
                            <th style="vertical-align: middle; width: 5%;text-align: center;">Case No</th>
                            <th style="vertical-align: middle; width: 20%;">Name of Examinee</th>
                            <th style="vertical-align: middle; width: 50%;">Name of Exam</th>
                            <th style="vertical-align: middle; width: 10%;">Roll No</th>
                            <th style="vertical-align: middle; width: 5%;">Category</th>
                            <th style="vertical-align: middle; width: 10%;">Punishment</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let obj of objSCopyCaseEntryService.arrCopyCaseEntry;let i = index">
                        <tr *ngIf="objPage.page_no == obj.page_no">
                            <td style="text-align: center;font-weight: 600;">
                                {{obj.case_no}} 
                            </td>
                            <td style="font-weight: 500;">
                                {{obj.student_first_name}} {{obj.student_middle_name}}
                                {{obj.student_last_name}}
                            </td>
                            <td>
                                {{obj.exam_name}} - 
                                {{obj.subject}}
                            </td>
                            <td>
                                {{obj.student_seat_no}} <br>
                                ({{obj.center_code}})
                            </td>
                            <td style="text-align: center;">
                                <label *ngIf="obj.annex_II_cat != 0">{{obj.annex_II_cat}}</label>
                            </td>
                            <td style="font-size: 13px;">
                                {{obj.annex_II_punishment}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style="font-size: 12px;font-weight: 600;text-align: right;margin-top: 2rem;">
                SIGNATURE OF FOIL CATEGORIZER
            </div>
        </div>
    </div>
</div>