import { Component, OnInit } from '@angular/core';
import { SCopyCaseEntryService } from '../pages/copy-case/copy-case-entry/s-copy-case-entry.service';

@Component({
  selector: 'app-print-case-annex-ii-report',
  templateUrl: './print-case-annex-ii-report.component.html',
  styleUrls: ['./print-case-annex-ii-report.component.scss']
})
export class PrintCaseAnnexIIReportComponent implements OnInit {

  
  breadCrumbItems: Array<{}>;
  
  constructor(public objSCopyCaseEntryService: SCopyCaseEntryService) { }

  ngOnInit(): void {
    this.objSCopyCaseEntryService.getCopyCasesII()
  }
}
