<div class="book">
    <div class="page">
        <div class="subpage">
            <div class="row">
                <div style="font-size: 10px;font-weight: 600;text-align: right;">
                    ST/conf./214/or-21/17-18/10TH <br>
                    Form No. 14 (Confi.)
                </div>
                <div
                    style="font-size: 16px;font-weight: 600;text-align: right;text-decoration: underline;margin: 5px 0;">
                    APPENDIX - 'C' <br>
                    FORMAT - A
                </div>
            </div>
            <div class="row">
                <div style="font-size: 23px;font-weight: 600;text-align: center;">
                    SANT GADGE BABA AMRAVATI UNIVERSITY, AMRAVATI
                </div>
                <div style="font-size: 16px;font-weight: 600;text-align: center;">
                    (Appendix to Ordinance No. 22 of 2001)
                </div>
                <div style="font-size: 13px;font-weight: 600;">
                    Statement of Candidate who is alleged to have used Unfairmeans at the University Examination.
                </div>
            </div>
            <br>
            <div class="row">
                <div style="display: grid;grid-template-columns: 30% 70%;line-height: 2.5rem;">
                    <div style="font-weight: 500;">
                        1. Name in Full :
                    </div>
                    <div style="font-weight: 600;text-decoration: underline;">
                        {{objSCopyCaseEntryService.pCopyCaseEntryReport.student_last_name}}
                        {{objSCopyCaseEntryService.pCopyCaseEntryReport.student_first_name}}
                        {{objSCopyCaseEntryService.pCopyCaseEntryReport.student_middle_name}}
                    </div>
                </div>
                <div style="display: grid;grid-template-columns: 30% 70%;line-height: 2.5rem;">
                    <div style="font-weight: 500;">
                        2. Address :
                    </div>
                    <div style="font-weight: 600;">
                        {{objSCopyCaseEntryService.pCopyCaseEntryReport.student_address}}
                    </div>
                </div>
                <div style="display: grid;grid-template-columns: 30% 70%;line-height: 2.5rem;">
                    <div style="font-weight: 500;">
                        3. Centre (No. & Name):
                    </div>
                    <div style="font-weight: 600;">
                        <span style="font-weight: 500;">No. - </span>
                        {{objSCopyCaseEntryService.pCopyCaseEntryReport.center_code}} &nbsp;&nbsp;&nbsp; <span
                            style="font-weight: 500;">Name -</span>
                        {{objSCopyCaseEntryService.pCopyCaseEntryReport.center_name}}
                    </div>
                </div>
                <div style="display: grid;grid-template-columns: 30% 70%;line-height: 2.5rem;">
                    <div style="font-weight: 500;">
                        4. Examination:
                    </div>
                    <div style="font-weight: 600;">
                        {{objSCopyCaseEntryService.pCopyCaseEntryReport.exam_name}}
                    </div>
                </div>
                <div style="display: grid;grid-template-columns: 30% 70%;line-height: 2.5rem;">
                    <div style="font-weight: 500;">
                        5. Date of Examination:
                    </div>
                    <div style="font-weight: 600;">
                        {{objSCopyCaseEntryService.pCopyCaseEntryReport.exam_date | date: 'dd-MM-yyyy'}}
                    </div>
                </div>
                <div style="display: grid;grid-template-columns: 30% 70%;line-height: 2.5rem;">
                    <div style="font-weight: 500;">
                        6. Paper No. & Subject:
                    </div>
                    <div style="font-weight: 600;">
                        {{objSCopyCaseEntryService.pCopyCaseEntryReport.paper}}
                        ({{objSCopyCaseEntryService.pCopyCaseEntryReport.subject}})
                    </div>
                </div>
                <div style="display: grid;grid-template-columns: 30% 70%;line-height: 2.5rem;">
                    <div style="font-weight: 500;">
                        7. Shift & Timing:
                    </div>
                    <div style="font-weight: 600;">
                        {{objSCopyCaseEntryService.pCopyCaseEntryReport.shift}}
                    </div>
                </div>
                <div style="display: grid;grid-template-columns: 30% 70%;line-height: 2.5rem;">
                    <div style="font-weight: 500;">
                        8. Seat No.
                    </div>
                    <div style="font-weight: 600;">
                        {{objSCopyCaseEntryService.pCopyCaseEntryReport.student_seat_no}}
                    </div>
                </div>
                <div style="display: grid;grid-template-columns: 30% 70%;line-height: 2.5rem;">
                    <div style="font-weight: 500;">
                        9. Mobile :
                    </div>
                    <div style="font-weight: 600;">
                        {{objSCopyCaseEntryService.pCopyCaseEntryReport.student_mobile}}
                    </div>
                </div>
            </div>
            <div class="row" style="margin-top: .5rem;">
                <div>
                    To, <br>
                    The Director <br>
                    Board of Examination & Evaluation <br>
                    Sant Gadge Baba Amravati University <br>
                    <span style="font-weight: 600;text-decoration: underline;">Amravati </span>
                </div>
            </div>
            <div class="row" style="margin-top: .5rem;">
                <p>Sir,</p>
                <p>
                    I appeared at the above examination held on <span style="font-weight: 600;">
                        {{objSCopyCaseEntryService.pCopyCaseEntryReport.exam_date | date: 'dd-MM-yyyy'}}
                    </span> at the
                    <span style="font-weight: 600;">{{objSCopyCaseEntryService.pCopyCaseEntryReport.center_code}} -
                        {{objSCopyCaseEntryService.pCopyCaseEntryReport.center_name}}</span>
                    College (Centre)
                </p>
                <p>
                    I give my statement as follows :-
                </p>
                <p style="font-weight: 500;text-align: justify;text-decoration: underline;">
                    {{objSCopyCaseEntryService.pCopyCaseEntryReport.student_statement}}
                </p>
            </div>
            <div class="row" style="margin-top: .5rem;">
                <p>
                    Place: ............................................
                </p>
            </div>
            <div class="row" style="display: grid;grid-template-columns: 60% 40%;">
                <div>
                    Date: <span style="font-weight: 600;">{{objSCopyCaseEntryService.pCopyCaseEntryReport.exam_date |
                        date: 'dd-MM-yyyy'}}</span> <br>
                    Time: <span
                        style="font-weight: 600;">{{objSCopyCaseEntryService.pCopyCaseEntryReport.shift_timing}}</span>
                </div>
                <div style="text-align: center;">
                    ........................................................................... <br>
                    Signature of the Candidate
                </div>
            </div>
            <div class="row" style="margin-top: 1.5rem;">
                <p style="text-align: justify;">
                    N.B. If the candidate refused to give statement the invigilator should mention the remark as refused
                    to give statement by the candidate and signed.
                </p>
            </div>
        </div>
    </div>
</div>


<div class="book">
    <div class="page">
        <div class="subpage">
            <div class="row">
                <div style="font-size: 10px;font-weight: 600;text-align: right;">
                    ST/conf./214/or-21/17-18/10TH <br>
                    Form No. 14 (Confi.)
                </div>
                <div
                    style="font-size: 16px;font-weight: 600;text-align: right;text-decoration: underline;margin: 5px 0;">
                    APPENDIX - 'C' <br>
                    FORMAT - B
                </div>
            </div>
            <div class="row">
                <div style="font-size: 23px;font-weight: 600;text-align: center;">
                    SANT GADGE BABA AMRAVATI UNIVERSITY, AMRAVATI
                </div>
                <div style="font-size: 18px;font-weight: 600;text-align: center;">
                    Report of the Invigilator / Co-officer / Officer-in-charge
                </div>
            </div>
            <br>
            <div class="row">
                <div style="display: grid;grid-template-columns: 55% 17% 2% 26%">
                    <div></div>
                    <div>
                        Block No.
                    </div>
                    <div>:</div>
                    <div style="font-weight: 600;">
                        {{objSCopyCaseEntryService.pCopyCaseEntryReport.block_no}}
                    </div>
                </div>
                <div style="display: grid;grid-template-columns: 55% 17% 2% 26%">
                    <div></div>
                    <div>
                        Examination
                    </div>
                    <div>:</div>
                    <div style="font-weight: 600;">
                        {{objSCopyCaseEntryService.pCopyCaseEntryReport.exam_name}}
                    </div>
                </div>
                <div style="display: grid;grid-template-columns: 55% 17% 2% 26%">
                    <div></div>
                    <div>
                        Subject
                    </div>
                    <div>:</div>
                    <div style="font-weight: 600;">
                        {{objSCopyCaseEntryService.pCopyCaseEntryReport.subject}}
                    </div>
                </div>
                <div style="display: grid;grid-template-columns: 55% 17% 2% 26%">
                    <div></div>
                    <div>
                        Paper
                    </div>
                    <div>:</div>
                    <div style="font-weight: 600;">
                        {{objSCopyCaseEntryService.pCopyCaseEntryReport.paper}}
                    </div>
                </div>
                <div style="display: grid;grid-template-columns: 55% 17% 2% 26%">
                    <div></div>
                    <div>
                        Date
                    </div>
                    <div>:</div>
                    <div style="font-weight: 600;">
                        {{objSCopyCaseEntryService.pCopyCaseEntryReport.exam_date | date: 'dd-MM-yyyy'}}
                    </div>
                </div>
            </div>
            <div class="row">
                <div>
                    To,
                </div>
                <div style="margin-left: 1rem;">
                    The Director <br>
                    Board of Examination & Evaluation <br>
                    Sant Gadge Baba Amravati University <br>
                    <span style="font-weight: 600;text-decoration: underline;">Amravati </span>
                </div>
            </div>
            <div class="row" style="margin-top: 1rem;">
                <p style="line-height: 1.8rem;text-align: justify;">
                    Sir, <br>
                    <span style="margin-left: 2rem;"></span> I, the undersigned, invigilator appointed on the above
                    mentioned Block / Room at the <span
                        style="font-weight: 600;">{{objSCopyCaseEntryService.pCopyCaseEntryReport.exam_name}}</span>
                    Examination held at <span
                        style="font-weight: 600;">{{objSCopyCaseEntryService.pCopyCaseEntryReport.center_code}}
                        {{objSCopyCaseEntryService.pCopyCaseEntryReport.center_name}}</span> college (Centre).
                    I am hereby making report against Candidate, Seat No. <span style="font-weight: 600;">
                        {{objSCopyCaseEntryService.pCopyCaseEntryReport.student_seat_no}}</span>
                    Shri / Kum {{objSCopyCaseEntryService.pCopyCaseEntryReport.student_first_name}}
                    {{objSCopyCaseEntryService.pCopyCaseEntryReport.student_middle_name}}
                    {{objSCopyCaseEntryService.pCopyCaseEntryReport.student_last_name}}
                    at the examinations, as follows:
                </p>
                <p style="line-height: 1.8rem;text-align: justify;">
                    {{objSCopyCaseEntryService.pCopyCaseEntryReport.invigilator_statement}}
                </p>
            </div>
            <div style="display: grid;grid-template-columns: 55% 45%; margin: 1rem 0;">
                <div></div>
                <div style="text-align: center;">
                    Yours FaithFully
                    <br><br><br>
                    (Invigilator)
                </div>
            </div>
            <div style="display: grid;grid-template-columns: 65% 10% 2% 23%">
                <div></div>
                <div>
                    Place:
                </div>
                <div>:</div>
                <div style="font-weight: 600;">

                </div>
            </div>
            <div style="display: grid;grid-template-columns: 65% 10% 2% 23%">
                <div></div>
                <div>
                    Date:
                </div>
                <div>:</div>
                <div style="font-weight: 600;">
                    {{objSCopyCaseEntryService.pCopyCaseEntryReport.exam_date | date: 'dd-MM-yyyy'}}
                </div>
            </div>
            <div style="display: grid;grid-template-columns: 65% 10% 2% 23%">
                <div></div>
                <div>
                    Time:
                </div>
                <div>:</div>
                <div style="font-weight: 600;">
                    {{objSCopyCaseEntryService.pCopyCaseEntryReport.shift_timing}}
                </div>
            </div>

            <div class="row">
                <p>
                    Name & Address of the Invigilator
                </p>
                <p style="font-weight: 600;text-decoration: underline;">
                    {{objSCopyCaseEntryService.pCopyCaseEntryReport.invigilator_name}}
                </p>
                <p>
                    Phone / Mobile No. <span style="font-weight: 600;text-decoration: underline;">
                        {{objSCopyCaseEntryService.pCopyCaseEntryReport.invigilator_mobile}} </span>
                </p>
            </div>

        </div>
    </div>
</div>


<div class="book">
    <div class="page">
        <div class="subpage">
            <div class="row">
                <p style="text-align: justify;line-height: 1.7rem;">
                    <span style="margin-left: 1rem;"></span> On the basis of the report made by Invigilator. I am of the
                    opinion that there is a prima
                    face case of Unfair Means resorted to by the aforesaid Candidate. Seat No
                    {{objSCopyCaseEntryService.pCopyCaseEntryReport.student_seat_no}}
                    and therefore the case be forwarded to the University for investigation.
                </p>
            </div>
            <div class="row" style="margin-left: 60%;margin-top: 1rem;">
                <p>
                    Signature of Co-Officer in charge <br>
                    Place : <br>
                    Date : <span
                        style="text-decoration: underline;font-weight: 600;">{{objSCopyCaseEntryService.pCopyCaseEntryReport.exam_date
                        | date: 'dd-MM-yyyy'}}</span><br>
                </p>
            </div>
            <div class="row">
                <p>
                    Name & address of the Co-officer-In charge <br><br>
                    <span style="font-weight: 600;text-decoration: underline;">
                        {{objSCopyCaseEntryService.pCopyCaseEntryReport.co_officer_in_charge_name}} 
                    </span>
                </p>
                <p>
                    Phone / Mobile No. <span style="font-weight: 600;text-decoration: underline;">
                        {{objSCopyCaseEntryService.pCopyCaseEntryReport.co_officer_in_charge_mobile}} 
                    </span>
                </p>
                <p style="text-align: justify;">
                    Forwarded to the director, Board of Examination & Evaluation, Sant Gadge Baba Amravati University,
                    Amravati.
                    The suspected case of unfairmeans with consfiscated material and answerbook/s for necessary action.
                </p>
            </div>
            <div class="row">
                <div style="font-size: 15px;font-weight: 600;text-align: center;">
                    Seal of the College / Institute / University (Centre)
                </div>
            </div>
            <div class="row">
                <p>
                    Place: <br>
                    Date : <span
                        style="text-decoration: underline;font-weight: 600;">{{objSCopyCaseEntryService.pCopyCaseEntryReport.exam_date
                        | date: 'dd-MM-yyyy'}}</span><br>
                </p>
            </div>
            <div class="row">
                <p>
                    <b>Encl: </b>
                </p>
                <p style="line-height: 2rem;">
                    1. _________________________ <br>
                    2. _________________________ <br>
                    3. _________________________ <br>
                    4. _________________________ <br>
                    5. _________________________
                </p>
            </div>
            <div class="row" style="margin-left: 50%;line-height: 2rem;">
                <div style="text-align: center;">
                    ---------------------------------- <br>
                    Signature of the Officer-In-Charge
                </div>
                <div style="display: grid;grid-template-columns: 40% 60%;">
                    <div>
                        Name: {{objSCopyCaseEntryService.pCopyCaseEntryReport.co_officer_in_charge_name}} 
                    </div>
                    <div>

                    </div>
                </div>
                <div style="display: grid;grid-template-columns: 40% 60%;">
                    <div>
                        Address: 
                    </div>
                    <div>

                    </div>
                </div>
                <div style="display: grid;grid-template-columns: 40% 60%;">
                    <div>
                        Phone / Mobile: {{objSCopyCaseEntryService.pCopyCaseEntryReport.co_officer_in_charge_mobile}} 
                    </div>
                    <div>

                    </div>
                </div>
            </div>
            <div class="row" style="margin-top: 1.5rem;">
                <p style="text-align: center;">
                    (N.B. Please enclose a copy of the relevant question paper alongwith the case)
                </p>
            </div>
        </div>
    </div>
</div>



<div class="book">
    <div class="page">
        <div class="subpage">
            <div class="row">
                <div style="font-size: 10px;font-weight: 600;text-align: right;">
                    ST/conf./214/or-21/17-18/10TH <br>
                    Form No. 14 (Confi.)
                </div>
                <div
                    style="font-size: 16px;font-weight: 600;text-align: right;text-decoration: underline;margin: 5px 0;">
                    APPENDIX - 'C' <br>
                    FORMAT - C
                </div>
            </div>
            <div class="row">
                <div style="font-size: 23px;font-weight: 600;text-align: center;">
                    FORM OF UNDERTAKING
                </div>
            </div>
            <br>
            <div class="row">
                <div style="display: grid;grid-template-columns: 30% 70%;line-height: 2.5rem;">
                    <div style="font-weight: 500;">
                        1. Name in Full :
                    </div>
                    <div style="font-weight: 600;text-decoration: underline;">
                        {{objSCopyCaseEntryService.pCopyCaseEntryReport.student_last_name}}
                        {{objSCopyCaseEntryService.pCopyCaseEntryReport.student_first_name}}
                        {{objSCopyCaseEntryService.pCopyCaseEntryReport.student_middle_name}}
                    </div>
                </div>
                <div style="display: grid;grid-template-columns: 30% 70%;line-height: 2.5rem;">
                    <div style="font-weight: 500;">
                        2. Address :
                    </div>
                    <div style="font-weight: 600;">
                        {{objSCopyCaseEntryService.pCopyCaseEntryReport.student_address}}
                    </div>
                </div>
                <div style="display: grid;grid-template-columns: 30% 70%;line-height: 2.5rem;">
                    <div style="font-weight: 500;">
                        3. Name of College:
                    </div>
                    <div style="font-weight: 600;">
                        <span style="font-weight: 500;">No. - </span>
                        {{objSCopyCaseEntryService.pCopyCaseEntryReport.college_code}} &nbsp;&nbsp;&nbsp; <span
                            style="font-weight: 500;">Name -</span>
                        {{objSCopyCaseEntryService.pCopyCaseEntryReport.college_name}}
                    </div>
                </div>
                <div style="display: grid;grid-template-columns: 30% 70%;line-height: 2.5rem;">
                    <div style="font-weight: 500;">
                        4. Centre of Exam:
                    </div>
                    <div style="font-weight: 600;">
                        <span style="font-weight: 500;">No. - </span>
                        {{objSCopyCaseEntryService.pCopyCaseEntryReport.center_code}} &nbsp;&nbsp;&nbsp; <span
                            style="font-weight: 500;">Name -</span>
                        {{objSCopyCaseEntryService.pCopyCaseEntryReport.center_name}}
                    </div>
                </div>
                <div style="display: grid;grid-template-columns: 30% 70%;line-height: 2.5rem;">
                    <div style="font-weight: 500;">
                        5. Examination:
                    </div>
                    <div style="font-weight: 600;">
                        {{objSCopyCaseEntryService.pCopyCaseEntryReport.exam_name}}
                    </div>
                </div>
                <div style="display: grid;grid-template-columns: 30% 70%;line-height: 2.5rem;">
                    <div style="font-weight: 500;">
                        6. Paper No. & Subject:
                    </div>
                    <div style="font-weight: 600;">
                        {{objSCopyCaseEntryService.pCopyCaseEntryReport.paper}}
                        ({{objSCopyCaseEntryService.pCopyCaseEntryReport.subject}})
                    </div>
                </div>
                <div style="display: grid;grid-template-columns: 30% 70%;line-height: 2.5rem;">
                    <div style="font-weight: 500;">
                        7. Session:
                    </div>
                    <div style="font-weight: 600;">
                        {{objSCopyCaseEntryService.pCopyCaseEntryReport.shift}}
                    </div>
                </div>
                <div style="display: grid;grid-template-columns: 30% 70%;line-height: 2.5rem;">
                    <div style="font-weight: 500;">
                        8. Seat No:
                    </div>
                    <div style="font-weight: 600;">
                        {{objSCopyCaseEntryService.pCopyCaseEntryReport.shift_timing}}
                    </div>
                </div>
            </div>
            <div class="row" style="margin-top: .5rem;">
                <div>
                    To, <br>
                    The Director <br>
                    Board of Examination & Evaluation <br>
                    Sant Gadge Baba Amravati University <br>
                    <span style="font-weight: 600;text-decoration: underline;">Amravati </span>
                </div>
            </div>
            <div class="row" style="margin-top: .5rem;">
                <p>Sir,</p>
                <p style="line-height: 1.8rem;text-align: justify;">
                    <span style="margin-left: 1rem;"></span> I the undersigned student of
                    <span>{{objSCopyCaseEntryService.pCopyCaseEntryReport.student_last_name}}
                        {{objSCopyCaseEntryService.pCopyCaseEntryReport.student_first_name}}
                        {{objSCopyCaseEntryService.pCopyCaseEntryReport.student_middle_name}}
                    </span>
                    College / Institution, appeared for
                    <span>{{objSCopyCaseEntryService.pCopyCaseEntryReport.exam_name}}</span>
                    Examination at the {{objSCopyCaseEntryService.pCopyCaseEntryReport.center_code}} - {{objSCopyCaseEntryService.pCopyCaseEntryReport.center_name}}
                    College (Centre) do hereby state, on solemn affirmation as under:
                </p>
                <p style="line-height: 1.8rem;text-align: justify;">I understand that I am involved in respect of an
                    alleged use of unfair means in the eexamination
                    hall and thereforem a case against me is being reported to the University.
                </p>
                <p style="line-height: 1.8rem;text-align: justify;">
                    That in spite of the registration of a case of Unfair Means against me I request the University
                    authorities to allow me to appear in the present paper and the papers to be set subsequently and /
                    or at the
                    University Examination to be held hereafter.
                </p>
                <p style="line-height: 1.8rem;text-align: justify;">
                    In case my request is granted, I do hereby agree that my appearance in the examination will be
                    provisional and subject to the decision of the University authorities in the matter of disposal of
                    the
                    case of alleged use of Unfair Means referred to above.
                </p>
                <p style="line-height: 1.8rem;text-align: justify;">
                    I also hereby agree that in the event of myself being found guilty at the time of inverstigation of
                    the said case, my
                    performance at the examination to which I have been permitted to appear provisionally, consequent
                    upon my special request is liable to be treated
                    as null and void.
                </p>
            </div>
        </div>
    </div>
</div>

<div class="book">
    <div class="page">
        <div class="subpage">
            <div class="row">
                <div style="font-size: 17px;font-weight: 600;text-align: center;">
                    In witness whereof I set my hand to this undertaking
                </div>
            </div>
            <br>
            <div style="display: grid;grid-template-columns: 55% 45%; margin: 1rem 0;">
                <div></div>
                <div style="text-align: center;">
                    ------------------------------------- <br>
                    Signature of Candidate
                    <br><br><br>
                    (Invigilator)
                </div>
            </div>
            <div style="display: grid;grid-template-columns: 65% 10% 2% 23%">
                <div></div>
                <div>
                    Date:
                </div>
                <div>:</div>
                <div style="font-weight: 600;">
                    {{objSCopyCaseEntryService.pCopyCaseEntryReport.exam_date | date: 'dd-MM-yyyy'}}
                </div>
            </div>
            <div style="display: grid;grid-template-columns: 65% 10% 2% 23%">
                <div></div>
                <div>
                    Time:
                </div>
                <div>:</div>
                <div style="font-weight: 600;">
                    {{objSCopyCaseEntryService.pCopyCaseEntryReport.shift_timing}}
                </div>
            </div>
        </div>
        <div class="row">
            Signed before me.
        </div>
        <div class="row" style="margin-top: 1rem;">
            1. {{objSCopyCaseEntryService.pCopyCaseEntryReport.officer_in_charge_name}} 
            <br><br>
            Officer-In-Charge of the Centre <br>
            and Rubber Stamp of the College / Institution / University
        </div>
        <div class="row">
            <div style="display: grid;grid-template-columns: 10% 40% 10% 40%;padding: 0;margin-top: 2rem;">
                <div>Date:</div>
                <div>
                    {{objSCopyCaseEntryService.pCopyCaseEntryReport.exam_date | date: 'dd-MM-yyyy'}}
                </div>
                <div>Time: </div>
                <div>
                    {{objSCopyCaseEntryService.pCopyCaseEntryReport.shift_timing}}
                </div>
            </div>
        </div>

        <div class="row" style="margin-top: 2rem;">
            2. {{objSCopyCaseEntryService.pCopyCaseEntryReport.co_officer_in_charge_name}} 
            <br><br>
            Co-officer-In-Charge
        </div>
        <div class="row" style="padding: 0;margin-top: 1rem;">
            Name : .......................................................... <br><br>
            Address : ..........................................................
        </div>
        <div class="row">
            <div style="display: grid;grid-template-columns: 10% 40% 10% 40%;padding: 0;margin-top: 2rem;">
                <div>Date:</div>
                <div>
                    {{objSCopyCaseEntryService.pCopyCaseEntryReport.exam_date | date: 'dd-MM-yyyy'}}
                </div>
                <div>Time: </div>
                <div>
                    {{objSCopyCaseEntryService.pCopyCaseEntryReport.shift_timing}}
                </div>
            </div>
        </div>
    </div>
</div>