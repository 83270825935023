export class mCenter {
    college_id: number = 0;
    college_code: number = 0;
    college_name: string = '';
    college_district: string = '';
}

export class mAcademicSession {
    session_id: number = 0
    session: string = ''
}

export class mPunishment {
    id: number = 0;
    punishment_id: number = 0;
    nature: string = '';
    quantum: string = '';
    punishment_one: number = 0;
    punishment_two: number = 0;
}

export class mCopyCaseEntry {
    app_id: number = 0;
    sr_no_new: number = 0;
    case_no: number = 0;
    student_first_name: string = '';
    student_middle_name: string = '';
    student_last_name: string = '';
    student_address: string = '';
    student_mobile: string = '';
    student_seat_no: string = '';
    center_code: number = 0;
    center_name: string = ''
    exam_name: string = '';
    exam_date: Date;
    paper: string = '';
    subject: string = '';
    block_no: string = '';
    shift: string = '';
    shift_timing: string = '';
    student_statement: string = '';
    invigilator_name: string = '';
    invigilator_mobile: string = '';
    invigilator_email: string = '';
    invigilator_statement: string = '';
    officer_in_charge_name: string = '';
    officer_in_charge_mobile: string = '';
    officer_in_charge_email: string = '';
    co_officer_in_charge_name: string = '';
    co_officer_in_charge_mobile: string = '';
    co_officer_in_charge_email: string = '';
    session: number = 2
    college_code: number
    college_name: string = ''
    annex_II_cat: number
    annex_III_cat: number
    annex_IV_cat: number
    annex_IV_assign: string=''
    annex_V_cat: number
    annex_II_punishment: string = ''
    annex_III_punishment: string = ''
    annex_IV_punishment: string = ''
    annex_V_punishment: string = ''
    page_no = 0
    annex_II_assign: string = ''
    annex_III_assign: string = ''
    sr_id_annex_4:number=0
}

export class mCenterList {
    center_code: number = 0
    college_name: string = ''
}



export interface mCopyCaseEntryAssignIV {
    annex_IV_assign: string;
    data: mCopyCaseEntryAssignIVData[];
}

export interface mCopyCaseEntryAssignIVData {
    app_id: number;
    student_first_name: string;
    student_middle_name: string;
    student_last_name: string;
    student_address: string;
    student_mobile: string;
    student_seat_no: string;
    center_code: number;
    exam_name: string;
    exam_date: Date;
    paper: string;
    subject: string;
    block_no: string;
    shift: string;
    shift_timing: string;
    student_statement: string;
    invigilator_name: string;
    invigilator_mobile: string;
    invigilator_email: string;
    invigilator_statement: string;
    officer_in_charge_name: string;
    officer_in_charge_mobile: string;
    officer_in_charge_email: string;
    co_officer_in_charge_name: string;
    co_officer_in_charge_mobile: string;
    co_officer_in_charge_email: string;
    college_code: number;
    session: number;
    annex_II_cat: number;
    annex_III_cat: number;
    annex_IV_cat: number;
    annex_IV_assign: string;
    annex_V_cat: number;
    annex_II_punishment: string;
    annex_III_punishment: string;
    annex_IV_punishment: string;
    annex_V_punishment: string;
}
