<div class="book">
    <div class="page">
        <div class="subpage">
            <div class="row" style="display: grid;grid-template-columns: 75% 25%;">
                <div style="font-size: 22px;font-weight: 500;">
                    SANT GADGE BABA AMRAVATI UNIVERSITY
                    <div style="font-size: 14px;font-weight: 600;text-align: center;text-decoration: underline;">
                        TABLE OF CASES REFERRED TO THIRD CATEGORIZER
                    </div>
                    <div style="font-size: 12px;font-weight: 500;text-align: center;text-transform: uppercase;">
                        Following are the cases wherein different category is give by the
                        foil categorizer and counter foil categorizer.
                    </div>
                </div>
                <div style="font-size: 12px;font-weight: 500;">
                    <span style="font-size: 16px;">ANNEXURE - V (i)</span> <br>
                    Page No - ..................... <br>
                    Date - {{todays_date | date: 'dd-MM-yyyy'}} <br>
                    Exam - WINTER-2023
                </div>
            </div>
            <br>
            <div class="row">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th style="text-align: center;vertical-align: middle; width: 5%;text-align: center;">
                                Sr No</th>
                            <th style="text-align: center;vertical-align: middle; width: 5%;text-align: center;">
                                Case No</th>
                            <th style="text-align: center;vertical-align: middle; width: 22%;">
                                Name of Examinee</th>
                            <th style="text-align: center;vertical-align: middle; width: 20%;">
                                Name of Exam (Centre)</th>
                            <th style="text-align: center;vertical-align: middle; width: 8%;">
                                Category given by FCAT
                            </th>
                            <th style="text-align: center;vertical-align: middle; width: 8%;">
                                FCAT No.
                            </th>
                            <th style="text-align: center;vertical-align: middle; width: 8%;">
                                Category given by CFCAT
                            </th>
                            <th style="text-align: center;vertical-align: middle; width: 8%;">
                                CFCAT No.
                            </th>
                            <th style="text-align: center;vertical-align: middle; width: 16%;">
                                No of appointed third categorizer
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let obj of objSCopyCaseEntryService.arrCopyCaseEntry;let i = index"
                        style="page-break-inside:avoid; page-break-after:auto">
                            <td style="text-align: center;font-weight: 600;">
                                {{i+1}} <br>
                            </td>
                            <td style="text-align: center;font-weight: 600;">
                                {{obj.case_no}} <br>
                            </td>
                            <td style="font-weight: 500;">
                                {{obj.student_first_name}} {{obj.student_middle_name}}
                                {{obj.student_last_name}} <br>
                                ({{obj.student_seat_no}})
                            </td>
                            <td style="font-weight: 500;">
                                {{obj.exam_name}} {{obj.paper}} ({{obj.center_code}})
                            </td>
                            <td style="text-align: center;">
                                <label *ngIf="obj.annex_II_cat != 0">
                                    {{obj.annex_II_cat | number:'2.0'}}
                                </label>
                            </td>
                            <td style="text-align: center;">
                                {{obj.annex_II_assign}}
                            </td>
                            <td style="text-align: center;">
                                <label *ngIf="obj.annex_III_cat != 0">
                                    {{obj.annex_III_cat | number:'2.0'}}
                                </label>
                            </td>
                            <td style="text-align: center;">
                                {{obj.annex_III_assign}}
                            </td>
                            <td style="text-align: center;">
                                <label *ngIf="obj.annex_IV_cat != 0">
                                    {{obj.annex_IV_assign}}
                                </label>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>