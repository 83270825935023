import { Component, OnInit } from '@angular/core';
import { SCopyCaseEntryService } from '../pages/copy-case/copy-case-entry/s-copy-case-entry.service';

@Component({
  selector: 'app-print-case-annex-iv-report-two',
  templateUrl: './print-case-annex-iv-report-two.component.html',
  styleUrls: ['./print-case-annex-iv-report-two.component.scss']
})
export class PrintCaseAnnexIVReportTwoComponent implements OnInit {

  breadCrumbItems: Array<{}>;

  constructor(public objSCopyCaseEntryService: SCopyCaseEntryService) { }

  ngOnInit(): void {
    this.objSCopyCaseEntryService.getCopyCasesIVAssignReport2()
  }
}
