import { Component, OnInit } from '@angular/core';
import { SCopyCaseEntryService } from '../pages/copy-case/copy-case-entry/s-copy-case-entry.service';

@Component({
  selector: 'app-print-case-annex-v-report',
  templateUrl: './print-case-annex-v-report.component.html',
  styleUrls: ['./print-case-annex-v-report.component.scss']
})
export class PrintCaseAnnexVReportComponent implements OnInit {

 todays_date= new Date()
  breadCrumbItems: Array<{}>;

  constructor(public objSCopyCaseEntryService: SCopyCaseEntryService) { }

  ngOnInit(): void {
    this.objSCopyCaseEntryService.getCopyCasesV()
  }
}
