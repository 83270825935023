import { Component, OnInit } from '@angular/core';
import { SCopyCaseEntryService } from '../pages/copy-case/copy-case-entry/s-copy-case-entry.service';

@Component({
  selector: 'app-print-annexure-v-a-to-v-d',
  templateUrl: './print-annexure-v-a-to-v-d.component.html',
  styleUrls: ['./print-annexure-v-a-to-v-d.component.scss']
})
export class PrintAnnexureVAToVDComponent implements OnInit {

  
  constructor(public objSCopyCaseEntryService: SCopyCaseEntryService) { }

  ngOnInit(): void {
    this.objSCopyCaseEntryService.getCopyCasesAnnexureV_a()
  }

}
