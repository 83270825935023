<div class="book">
    <div class="page">
        <div class="subpage">
            <div class="row">
                <div style="font-size: 16px;font-weight: 500;text-align: right;">
                    ANNEXURE - V (A)
                </div>
            </div>
            <div class="row">
                <div style="font-size: 16px;text-align: center;font-weight: 500;">
                    FOLLOWING ARE THE CASES WHERE FINAL CATEGORY (OF USE OF MALPRACTICES) <br>IS A RESULT OF UNANIMITY
                    IN DECIDING A CATEGORY BY
                    FOIL & COUNTER FOIL CATEGORIZER. <br>
                    WINTER-2023
                </div>
            </div>
            <div style="display: grid;grid-template-columns: 80% 20%;margin: 1rem 2rem;">
                <div>

                </div>
                <div style="text-align: center;font-weight: 600;">
                    TOTAL
                </div>
                <div style="display: grid;grid-template-columns: repeat(10, 10%);line-height: 1.8rem;">
                    <div *ngFor="let obj of objSCopyCaseEntryService.arrCopyCaseEntry3"
                        style="font-weight: 500;font-size: 14px;text-align: center;">
                        {{obj.case_no}}
                    </div>
                </div>
                <div style="border-left: 1px solid black;display: flex;align-items: center;justify-content: center;font-weight: 600;">
                    {{objSCopyCaseEntryService.annex_v_a_count}}
                </div>
            </div>

        </div>
    </div>

    <div class="page">
        <div class="subpage">
            <div class="row">
                <div style="font-size: 16px;font-weight: 500;text-align: right;">
                    ANNEXURE - V (B)
                </div>
            </div>
            <div class="row">
                <div style="font-size: 16px;text-align: center;font-weight: 500;">
                    FOLLOWING ARE THE CASES WHERE DIFFERENT CATEGORIZATION WAS DONE BY THE FOIL CATEGORIZER AND COUNTER
                    FOIL CATEGORIZER, HENCE THE CASES
                    WHERE REFERRED TO THE THIRD CATEGORIZER. OUT OF THE THREE, TWO WERE IN AGREEMENT. THE FINAL CATEGORY
                    GIOVEN IS A RESULT TO SUCH AGREEMENT. <br>
                    WINTER-2023
                </div>
            </div>
            <div style="display: grid;grid-template-columns: 80% 20%;margin: 1rem 2rem;">
                <div>

                </div>
                <div style="text-align: center;font-weight: 600;">
                    TOTAL
                </div>
                <div style="display: grid;grid-template-columns: repeat(10, 10%);line-height: 1.8rem;">
                    <div *ngFor="let obj of objSCopyCaseEntryService.arrCopyCaseEntry4"
                        style="font-weight: 500;font-size: 14px;">
                        {{obj.case_no}}
                    </div>
                </div>
                <div style="border-left: 1px solid black;display: flex;align-items: center;justify-content: center;font-weight: 600;">
                    {{objSCopyCaseEntryService.annex_v_b_count}}
                </div>
            </div>

        </div>
    </div>

    <div class="page">
        <div class="subpage">
            <div class="row">
                <div style="font-size: 16px;font-weight: 500;text-align: right;">
                    ANNEXURE - V (C)
                </div>
            </div>
            <div class="row">
                <div style="font-size: 16px;text-align: center;font-weight: 500;">
                    STATEMENT SHOWING THE NO. OF THE CASES WHEREE THE CATEGORIES ALLOTED BY ALL THE THREE CATEGORIZERS
                    ARE DIFFERENT OR CATEGORIZERS
                    HAVE GIVEN SUCH CATEGORIES WHICH COULD NOT LEAD TO FINAL CATEGORIZATION. HENCE FOLLOWING CASES WERE
                    REFFERED TO 48(5)(a) COMMITTEE. <br>
                    WINTER-2023
                </div>
            </div>

            <div style="display: grid;grid-template-columns: 80% 20%;margin: 1rem 2rem;">
                <div>

                </div>
                <div style="text-align: center;font-weight: 600;">
                    TOTAL
                </div>
                <div style="display: grid;grid-template-columns: repeat(10, 10%);line-height: 1.8rem;">
                    <div *ngFor="let obj of objSCopyCaseEntryService.arrCopyCaseEntry5"
                        style="font-weight: 500;font-size: 14px;">
                        {{obj.case_no}}
                    </div>
                </div>
                <div style="border-left: 1px solid black;display: flex;align-items: center;justify-content: center;font-weight: 600;">
                    {{objSCopyCaseEntryService.annex_v_c_count}}
                </div>
            </div>

        </div>
    </div>

    <div class="page">
        <div class="subpage">
            <div class="row">
                <div style="font-size: 16px;font-weight: 500;text-align: right;">
                    ANNEXURE - V (D)
                </div>
            </div>
            <div class="row">
                <div style="font-size: 16px;text-align: center;font-weight: 500;">
                    FOLLOWING ARE THE CASES WHERE THE STUDENT HAS USED THE MALPRACTICES MORE THAN ONCE AND HENCE HAVING THE SAME ROLL NO. IS ALLOTED DIFFERENT CASE NUMBERS. <br>
                    WINTER-2023
                </div>
            </div>

            <div style="display: grid;grid-template-columns: 80% 20%;margin: 1rem 2rem;">
                <div>

                </div>
                <div style="text-align: center;font-weight: 600;">
                    TOTAL
                </div>
                <div style="display: grid;grid-template-columns: repeat(10, 10%);line-height: 1.8rem;">
                  
                </div>
                <div style="border-left: 1px solid black;display: flex;align-items: center;justify-content: center;font-weight: 600;">
                    000
                </div>
            </div>

        </div>
    </div>
</div>